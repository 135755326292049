import gql from 'graphql-tag';

export const SIGNIN_USER_MUTATION = gql`
  mutation AuthLogin($data: LoginInput!) {
    AuthLogin(data: $data) {
      accessToken
      email
      permissions
      roles
      userId
    }
  }
`;

export const RECOVER_PASSWORD_MUTATION = gql`
  mutation AuthRecoverPassword($data: RecoverInput!) {
    AuthRecoverPassword(data: $data) {
      message
    }
  }
`;

export const ACTIVATE_ACCOUNT_MUTATION = gql`
  mutation AuthActivate($data: ActivateInput!) {
    AuthActivate(data: $data) {
      message
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation AuthResetPassword($data: ResetInput!) {
    AuthResetPassword(data: $data) {
      message
    }
  }
`;
