import {
  ExtraOptions,
  PreloadAllModules,
  Route,
  RouterModule,
} from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { NgModule } from '@angular/core';
import { CanActivateActivation } from '@modules/auth/activation/activation.gaurd';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const routes: Route[] = [
  // Redirect empty path to '/example'
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

  // Redirect signed in user to the '/example'
  //
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

  // Auth routes for guests
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'confirmation-required',
        loadChildren: () =>
          import(
            'app/modules/auth/confirmation-required/confirmation-required.module'
          ).then((m) => m.AuthConfirmationRequiredModule),
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import(
            'app/modules/auth/forgot-password/forgot-password.module'
          ).then((m) => m.AuthForgotPasswordModule),
      },
      {
        path: 'reset-password',
        loadChildren: () =>
          import('app/modules/auth/reset-password/reset-password.module').then(
            (m) => m.AuthResetPasswordModule
          ),
      },
      {
        path: 'sign-in',
        loadChildren: () =>
          import('app/modules/auth/sign-in/sign-in.module').then(
            (m) => m.AuthSignInModule
          ),
      },
      {
        path: 'sign-up',
        loadChildren: () =>
          import('app/modules/auth/sign-up/sign-up.module').then(
            (m) => m.AuthSignUpModule
          ),
      },
      {
        path: 'activation',
        canActivate: [CanActivateActivation],
        loadChildren: () =>
          import('app/modules/auth/activation/activation.module').then(
            (m) => m.AuthActivationModule
          ),
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () =>
          import('app/modules/auth/sign-out/sign-out.module').then(
            (m) => m.AuthSignOutModule
          ),
      },
      {
        path: 'unlock-session',
        loadChildren: () =>
          import('app/modules/auth/unlock-session/unlock-session.module').then(
            (m) => m.AuthUnlockSessionModule
          ),
      },
    ],
  },

  // Landing routes
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      // {
      //   path: 'home',
      //   // loadChildren: () =>
      //   //   import('app/modules/landing/home/home.module').then(
      //   //     (m) => m.LandingHomeModule
      //   //   ),
      // },
      {
        path: 'public',
        loadChildren: () =>
          import('./modules/public/public.module').then((m) => m.PublicModule),
      },
    ],
  },

  // Admin routes
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('app/modules/admin/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'departments',
        loadChildren: () =>
          import('app/modules/admin/departments/departments.module').then(
            (m) => m.DepartmentsModule
          ),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('app/modules/admin/users/users.module').then(
            (m) => m.UsersModule
          ),
      },
      {
        path: 'categories',
        loadChildren: () =>
          import('app/modules/admin/categories/categories.module').then(
            (m) => m.CategoriesModule
          ),
      },
      {
        path: 'occurrences',
        loadChildren: () =>
          import('app/modules/admin/occurrences/occurrences.module').then(
            (m) => m.OccurrencesModule
          ),
      },
      {
        path: 'reporting-channels',
        loadChildren: () =>
          import(
            'app/modules/admin/reporting-channel/reporting-channel.module'
          ).then((m) => m.ReportingChannelModule),
      },
      {
        path: 'pages-languages',
        loadChildren: () =>
          import('app/modules/admin/pages/pages.module').then(
            (m) => m.PagesModule
          ),
      },
      {
        path: 'pricing',
        loadChildren: () =>
          import('app/modules/admin/modern/modern.module').then(
            (m) => m.PricingModernModule
          ),
      },
    ],
  },
];

const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
  paramsInheritanceStrategy: 'always',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerConfig)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
