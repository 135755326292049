/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const futuristicNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'basic',
    icon: 'heroicons_outline:home',
    link: '/dashboard',
  },
  {
    id: 'occurrences',
    title: 'Occurrences',
    type: 'basic',
    icon: 'heroicons_outline:document-text',
    link: '/occurrences',
  },
  {
    id: 'statistics',
    title: 'Statistics',
    type: 'basic',
    icon: 'heroicons_outline:presentation-chart-bar',
    link: '/statistics',
    disabled: true,
  },
  {
    id: 'departments',
    title: 'Departments',
    type: 'basic',
    icon: 'heroicons_outline:library',
    link: '/departments',
  },
  {
    id: 'users',
    title: 'Users',
    type: 'basic',
    icon: 'heroicons_outline:users',
    link: '/users',
  },
  {
    id: 'categories',
    title: 'Categories',
    type: 'basic',
    icon: 'heroicons_outline:collection',
    link: '/categories',
  },
  {
    id: 'reporting_channels',
    title: 'Reporting Link',
    type: 'basic',
    icon: 'heroicons_outline:link',
    link: '/reporting-channels',
  },
  {
    id: 'languages',
    title: 'Languages',
    type: 'basic',
    icon: 'heroicons_outline:translate',
    link: '/languages',
  },
  {
    id: 'form-builder',
    title: 'Form Builder',
    type: 'basic',
    icon: 'heroicons_outline:clipboard-list',
    link: '/form-builder',
    disabled: true,
  },
  {
    id: 'brand',
    title: 'Custom Design',
    type: 'basic',
    icon: 'heroicons_outline:puzzle',
    link: '/brand',
    disabled: true,
  },
  {
    id: 'settings',
    title: 'Settings',
    type: 'basic',
    icon: 'heroicons_outline:adjustments',
    link: '/settings',
    disabled: true,
  },
  {
    id: 'support',
    title: 'Support',
    type: 'basic',
    icon: 'heroicons_outline:support',
    link: '/support',
    disabled: true,
  },
];

export const modernNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'basic',
    icon: 'heroicons_outline:home',
    link: '/dashboard',
  },
  {
    id: 'occurrences',
    title: 'Complaints',
    type: 'basic',
    icon: 'heroicons_outline:document-text',
    link: '/occurrences',
  },
  {
    id: 'statistics',
    title: 'Reports',
    type: 'basic',
    icon: 'heroicons_outline:presentation-chart-bar',
    link: '/statistics',
    disabled: true,
  },

  {
    id: 'channels',
    title: 'Portals',
    type: 'group',
    icon: 'heroicons_outline:link',
    // link: '/reporting-channels',
    children: [
      {
        id: 'channels.link',
        title: 'Reporting Link',
        type: 'basic',
        icon: 'heroicons_outline:link',
        link: '/reporting-channels',
      },
      {
        id: 'channels.languages',
        title: 'Pages and languages',
        type: 'basic',
        icon: 'heroicons_outline:translate',
        link: '/pages-languages',
      },
      {
        id: 'channels.form-builder',
        title: 'Form Builder',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-list',
        link: '/form-builder',
        disabled: true,
      },
    ],
  },
  {
    id: 'settingsX',
    title: 'Settings',
    type: 'group',
    icon: 'heroicons_outline:adjustments',
    children: [
      {
        id: 'settingsX.brand',
        title: 'Design',
        type: 'basic',
        icon: 'heroicons_outline:puzzle',
        link: '/brand',
        disabled: true,
      },
      {
        id: 'settingsX.departments',
        title: 'Departments',
        type: 'basic',
        icon: 'heroicons_outline:library',
        link: '/departments',
      },
      {
        id: 'settingsX.categories',
        title: 'Categories',
        type: 'basic',
        icon: 'heroicons_outline:collection',
        link: '/categories',
      },
      {
        id: 'settingsX.users',
        title: 'Users',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/users',
        disabled: true,
      },
    ],
  },

  // {
  //   id: 'support',
  //   title: 'Support',
  //   type: 'basic',
  //   icon: 'heroicons_outline:support',
  //   link: '/support',
  //   disabled: true,
  // },
];

export const defaultNavigation: FuseNavigationItem[] = futuristicNavigation;
export const compactNavigation: FuseNavigationItem[] = futuristicNavigation;
export const horizontalNavigation: FuseNavigationItem[] = modernNavigation;
