import gql from 'graphql-tag';

export const GET_ALL_COMPANIES_QUERY = gql`
  query CompanyFindAll($where: CompanyWhereInput) {
    CompanyFindAll(where: $where) {
      address
      city
      countryId
      createdAt
      createdBy
      email
      id
      name
      phone
      phoneExt
      postalCode
    }
  }
`;

export const GET_COMPANY_QUERY = gql`
  query CompanyFindOne($where: CompanyWhereUniqueInput!) {
    CompanyFindOne(where: $where) {
      address
      city
      countryId
      createdAt
      createdBy
      email
      id
      name
      phone
      phoneExt
      postalCode
      CompanySettings {
        logo
        encryptionKey {
          key
        }
      }
    }
  }
`;
