// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  graphql: 'https://txc-api.targx.io/graphql',
  api: 'https://txc-api.targx.io/',
  base: 'http://localhost:4200',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  KEY_LOCAL_STORAGE: 'txc-dev-access_token',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  KEY_LOCAL_STORAGE_COMPANY: 'txc-dev-company',
  passphrase:
    'Wheel There Motherly Develop Sink Motion Funny Desk Bus Origin 3',
  aeskey: '@NcRfUjXn2r5u8x/A?D*G-KaPdSgVkYp',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
