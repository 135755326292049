import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject, tap } from 'rxjs';
import { User } from 'app/core/user/user.types';
import { Apollo } from 'apollo-angular';
import { AuthUtils } from '@core/auth/auth.utils';
import { GET_USERS_QUERY, GET_USER_QUERY } from '@targx/graphql-queries/user';
import { environment } from 'environments/environment';
import { Storage } from '@targx/libs/storage';
import { CompanyService } from '@core/company/company.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
  public _currentUser: User;
  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private apollo: Apollo,
    private companyService: CompanyService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: User) {
    // Store the value
    this._currentUser = value;
    this._user.next(value);
  }

  get user$(): Observable<User> {
    return this._user.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data
   */
  get(): Observable<User> {
    const accessToken = Storage.get(environment.KEY_LOCAL_STORAGE);
    const decodedToken = AuthUtils._decodeToken(accessToken);
    return this.apollo
      .watchQuery<User>({
        query: GET_USER_QUERY,
        variables: {
          where: {
            email: decodedToken.email,
          },
        },
      })
      .valueChanges.pipe(
        map(({ data }) => {
          console.log('[] data', data);
          this._user.next(data.UserFindOne);
          return data.UserFindOne;
        })
      );
    // return this._httpClient.get<User>('api/common/user').pipe(
    //   tap((user) => {
    //     this._user.next(user);
    //   })
    // );
  }

  getByEmail(email: string): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: GET_USER_QUERY,
        variables: {
          where: {
            email,
          },
        },
      })
      .valueChanges.pipe(map(({ data }) => data.UserFindOne));
  }

  /**
   * Update the user
   *
   * @param user
   */
  update(user: User): Observable<any> {
    return this._httpClient.patch<User>('api/common/user', { user }).pipe(
      map((response) => {
        this._user.next(response);
      })
    );
  }

  getAllByCompany(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: GET_USERS_QUERY,
        variables: {
          where: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            User2Company: {
              some: {
                companyId: {
                  equals: this.companyService.currentCompany.id,
                },
              },
            },
          },
        },
      })
      .valueChanges.pipe(map(({ data }) => data.UserFindAll));
  }
}
