import gql from 'graphql-tag';

export const GET_USER_QUERY = gql`
  query UserFindOne($where: UserWhereUniqueInput!) {
    UserFindOne(where: $where) {
      createdAt
      createdBy
      deleted
      deletedAt
      deletedBy
      email
      emailVerifiedAt
      firstName
      hasBackofficeAccess
      id
      isActive
      lastName
      loginDeactivatedAt
      permissions {
        permissionId
        permission {
          name
        }
      }
      phone
      phoneExt
      profilePicture
      profilePictureId
      roles {
        role {
          name
        }
      }
      superUser
      title
      twoFactorAuthEnabled
      updatedAt
      updatedBy
    }
  }
`;

export const GET_USERS_QUERY = gql`
  query UserFindAll($where: UserWhereInput) {
    UserFindAll(where: $where) {
      id
      firstName
      lastName
      email
    }
  }
`;
