import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraphqlModule } from './services/graphql/graphql.module';
import { ApiModule } from './services/api/api.module';
import { TxDragDropUploadModule } from './components/tx-drag-drop-upload/tx-drag-drop-upload.module';
import { IconsModule } from '@core/icons/icons.module';
import { TranslocoCoreModule } from '@core/transloco/transloco.module';

/**
 * Migrate Modules TranslocoCoreModule, IconsModule to Targx Core
 */

@NgModule({
  imports: [
    CommonModule,
    GraphqlModule,
    ApiModule,
    TranslocoCoreModule,
    IconsModule,
  ],
  exports: [GraphqlModule, ApiModule],
})
export class TargxModule {
  /**
   * Constructor
   *
   * @param parentModule
   */
  constructor(@Optional() @SkipSelf() parentModule?: TargxModule) {
    if (parentModule) {
      throw new Error(
        'TargxModule has already been loaded. Import this module in the AppModule only!'
      );
    }
  }
}
