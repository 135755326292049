import { Injectable } from '@angular/core';
import {
  GET_ALL_COMPANIES_QUERY,
  GET_COMPANY_QUERY,
} from '@targx/graphql-queries/company';
import { Apollo } from 'apollo-angular';
import { environment } from 'environments/environment';
import { tap, map } from 'rxjs';
import { Observable, ReplaySubject } from 'rxjs';
import { Company } from './company.types';
import { Storage } from '@targx/libs/storage';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private _company: ReplaySubject<Company> = new ReplaySubject<Company>(1);
  private _companies: ReplaySubject<Company[]> = new ReplaySubject<Company[]>(
    1
  );

  private _currentCompany: Company;

  constructor(private apollo: Apollo) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for company
   *
   * @param value
   */
  set company(value: Company) {
    // Store the value
    Storage.set(environment.KEY_LOCAL_STORAGE_COMPANY, value.id);
    this._currentCompany = value;
    this._company.next(value);
  }

  get company$(): Observable<Company> {
    return this._company.asObservable();
  }

  get currentCompany(): Company {
    return this._currentCompany;
  }

  /**
   * Setter & getter for companies
   *
   * @param value
   */
  set companies(value: Company[]) {
    // Store the value
    this._companies.next(value);
  }

  get companies$(): Observable<Company[]> {
    return this._companies.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  get(companyId: string) {
    return this.apollo
      .watchQuery<Company>({
        query: GET_COMPANY_QUERY,
        variables: {
          where: {
            id: companyId,
          },
        },
      })
      .valueChanges.pipe(
        map(({ data }) => data.CompanyFindOne),
        tap((company) => (this.company = company))
      );
  }

  getAllByUserId(userId: string) {
    return this.apollo
      .watchQuery<Company>({
        query: GET_ALL_COMPANIES_QUERY,
        variables: {
          where: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            User2Company: {
              some: {
                userId: {
                  equals: userId,
                },
              },
            },
          },
        },
      })
      .valueChanges.pipe(map(({ data }) => data.CompanyFindAll));
  }
}
