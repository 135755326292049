import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  catchError,
  combineLatest,
  map,
  Observable,
  of,
  switchMap,
  throwError,
  tap,
} from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { Apollo } from 'apollo-angular';
import {
  ACTIVATE_ACCOUNT_MUTATION,
  RECOVER_PASSWORD_MUTATION,
  SIGNIN_USER_MUTATION,
  RESET_PASSWORD_MUTATION,
} from '@targx/graphql-queries/authentication';
import { environment } from 'environments/environment';
import { CompanyService } from '@core/company/company.service';
import { Storage } from '@targx/libs/storage';

@Injectable()
export class AuthService {
  public get apollo(): Apollo {
    return this._apollo;
  }

  public set apollo(value: Apollo) {
    this._apollo = value;
  }

  private _authenticated: boolean = false;

  private _activationToken: string;

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService,
    private _companyService: CompanyService,
    private _apollo: Apollo,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    Storage.set(environment.KEY_LOCAL_STORAGE, token);
  }

  get accessToken(): string {
    return Storage.get(environment.KEY_LOCAL_STORAGE) ?? '';
  }

  set activationToken(token: string) {
    this._activationToken = token;
  }

  get activationToken(): string {
    return this._activationToken;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: RECOVER_PASSWORD_MUTATION,
        variables: {
          data: {
            email,
          },
        },
      })
      .pipe(map(({ data }) => data.AuthRecoverPassword));
  }

  forgotPasswordHttp(email: string): Observable<any> {
    return this._httpClient.post('api/auth/forgot-password', email);
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(token: string, password: string): Observable<any> {
    // Throw error, if the user is already logged in
    if (!token) {
      return throwError('Token is wrong.');
    }

    return this.apollo
      .mutate<any>({
        mutation: RESET_PASSWORD_MUTATION,
        variables: {
          data: {
            resetToken: token,
            password: password,
          },
        },
      })
      .pipe(
        switchMap((result: any) => of(result)),
        catchError((error) => {
          // Log the error
          console.error(error);
          // Throw an error
          return throwError(error);
        }),
      );
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { email: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    return this.apollo
      .mutate<any>({
        mutation: SIGNIN_USER_MUTATION,
        variables: {
          data: {
            email: credentials.email,
            password: credentials.password,
          },
        },
      })
      .pipe(
        switchMap((result: any) => {
          const response = result.data.AuthLogin;

          // Store the access token in the local storage
          this.accessToken = response.accessToken;

          // Set the authenticated flag to true
          this._authenticated = true;

          // Return a new observable with the response
          return of(response);
        }),
        switchMap((response: any) =>
          combineLatest([
            this._userService.getByEmail(response.email),
            this._companyService.getAllByUserId(response.userId),
          ]).pipe(map(([user, companies]) => [user, companies, response])),
        ),
        tap(([user, companies, response]) => {
          // Store the user on the user service
          this._userService.user = user;

          // Store the user on the user service
          this._companyService.companies = companies;
          const companyId = Storage.get(environment.KEY_LOCAL_STORAGE_COMPANY);

          if (!companyId) {
            this._companyService.company = companies[0];
          }
        }),
        switchMap(([user, companies, response]) => {
          const companyId = Storage.get(environment.KEY_LOCAL_STORAGE_COMPANY);

          if (companyId) {
            return this._companyService.get(companyId);
          }

          if (companies.length === 1) {
            return this._companyService.get(companies[0].id);
          }

          return of([user, companies, response]);
        }),
      );
  }

  signInHttp(credentials: {
    email: string;
    password: string;
  }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    return this._httpClient.post('api/auth/sign-in', credentials).pipe(
      switchMap((response: any) => {
        // Store the access token in the local storage
        this.accessToken = response.accessToken;

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service
        this._userService.user = response.user;

        // Return a new observable with the response
        return of(response);
      }),
    );
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    const decoded = AuthUtils._decodeToken(this.accessToken);

    return combineLatest([
      this._userService.getByEmail(decoded.email),
      this._companyService.getAllByUserId(decoded.userId),
    ]).pipe(
      tap(([user, companies]) => {
        // Store the user on the user service
        this._userService.user = user;

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service
        this._companyService.companies = companies;
      }),
      switchMap(([response, companies]) => {
        const companyId = Storage.get(environment.KEY_LOCAL_STORAGE_COMPANY);

        if (companyId) {
          return this._companyService.get(companyId);
        }

        if (companies.length === 1) {
          return this._companyService.get(companies[0].id);
        }

        return of(response);
      }),
    );

    // Sign in using the token
    // return this._httpClient
    //   .post('api/auth/sign-in-with-token', {
    //     accessToken: this.accessToken,
    //   })
    //   .pipe(
    //     catchError(() =>
    //       // Return false
    //       of(false)
    //     ),
    //     switchMap((response: any) => {
    //       // Replace the access token with the new one if it's available on
    //       // the response object.
    //       //
    //       // This is an added optional step for better security. Once you sign
    //       // in using the token, you should generate a new one on the server
    //       // side and attach it to the response object. Then the following
    //       // piece of code can replace the token with the refreshed one.
    //       if (response.accessToken) {
    //         this.accessToken = response.accessToken;
    //       }

    //       // Set the authenticated flag to true
    //       this._authenticated = true;

    //       // Store the user on the user service
    //       this._userService.user = response.user;

    //       // Return true
    //       return of(true);
    //     })
    //   );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Remove the access token from the local storage
    Storage.remove(environment.KEY_LOCAL_STORAGE);
    Storage.clear();

    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: {
    name: string;
    email: string;
    password: string;
    company: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/sign-up', user);
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: {
    email: string;
    password: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    // If the access token exists and it didn't expire, sign in using it
    return this.signInUsingToken();
  }

  activationAccount(token: string, password: string): Observable<any> {
    // Throw error, if the user is already logged in
    if (!token) {
      return throwError('Token is wrong.');
    }

    return this.apollo
      .mutate<any>({
        mutation: ACTIVATE_ACCOUNT_MUTATION,
        variables: {
          data: {
            activationToken: token,
            password: password,
          },
        },
      })
      .pipe(
        switchMap((result: any) => of(result)),
        catchError((error) => {
          // Log the error
          console.error(error);
          // Throw an error
          return throwError(error);
        }),
      );
  }
}
