<!-- Footer -->
<div
  class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden"
>
  <span class="font-medium text-secondary"
    >GDD - Gestão de Denúncias &copy; {{ currentYear }} - Engineered by
    <a
      class="text-blue-700 hover:underline"
      href="https://targx.com"
      target="_blank"
      >TargX</a
    >
    &bullet; v{{ version }}</span
  >
</div>
